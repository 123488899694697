@import "font.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #0000FB;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-text {
  color: #fdfd04;
  font-family: VCR_OSD;
  font-size: 4.4vw;
  filter: blur(1px);
  align-self: center;
  padding-left: 1.7vw;
}

.App-border {
  border: solid #fdfd04;
  border-width: 5px;
  filter: blur(1px);
  width: 35vw;
  height: 8vw;
  position: absolute;
  display: flex;
}

.App-text:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 3000ms infinite;
  animation: ellipsis steps(4, end) 3000ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 3.5vw;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 3.5vw;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
